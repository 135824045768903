export const route = {
  signIn: '/signIn',
  newCommerce: '/nuevo-comercio',
  aproveCommerce: '/aprobacion-comercios',
  listCommerce: '/lista-comercios',
  commerceDetails: '/detalles-comercio/:idCommerce',
  listSellers: '/lista-vendedores',
  sellerDetails: '/detalles-vendedor/:idSeller',
  welcomeCommerce: '/bienvenida-comercio/:idSolicitud',
  commerceForm: '/formulario-comercio/:idCommerce',
  welcomeBranches: '/bienvenida-sucursales/:idCommerce',
  listBranches: '/listado-sucursales/:idCommerce',
  branchForm: '/carga-sucursal/:idCommerce/sucursal/:idBranch?', // agregar
  listBranchesFilter: '/lista-sucursales',
  branchDetails: '/detalles-sucursal/:idBranch',
};

export default route;
