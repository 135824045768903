import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getFilteredUsersAffiliation, getStatusUsersAffiliation } from '../../api/services';
import { userStatusMapper } from '../../utils/statusMapper';
import i18n from './i18n';
import Loading from './Loading';
import SelectMenuItems from './SelectMenuItems';

const filterSellers = ({ showResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusEnum, setStatusEnum] = useState([{
    id: i18n.filterCommerceViewAll,
    value: i18n.filterCommerceViewAll,
  }]);
  const [filterValues, setFilterValues] = useState({
    pageNumber: 1,
    pageSize: 100,
    firstName: '',
    lastName: '',
    identificationNumber: '',
    status: i18n.filterSellerViewAll,
    createdDateFrom: null,
    createdDateTo: null,
  });

  if (filterValues.createdDateFrom === '') {
    filterValues.createdDateFrom = null;
  }
  if (filterValues.createdDateTo === '') {
    filterValues.createdDateTo = null;
  }
  if (filterValues.status === '') {
    filterValues.status = i18n.filterCommerceViewAll;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleDateChange = (e, name) => {
    const date = (e.toISOString());
    setFilterValues({ ...filterValues, [name]: date });
  };

  const handleInputChangeStatus = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: userStatusMapper(value) });
  };

  const filterSellerHandler = async () => {
    setIsLoading(true);
    try {
      if (filterValues.createdDateFrom === null) {
        filterValues.createdDateFrom = '';
      }
      if (filterValues.createdDateTo === null) {
        filterValues.createdDateTo = '';
      }
      if (filterValues.status === i18n.filterCommerceViewAll) {
        filterValues.status = '';
      }
      filterValues.status = userStatusMapper(filterValues.status);

      const { data } = await getFilteredUsersAffiliation(filterValues);

      filterValues.status = userStatusMapper(filterValues.status);
      showResults(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  const getStatusUsers = async () => {
    setIsLoading(true);
    try {
      const { data } = await getStatusUsersAffiliation();

      for (let i = 0; i < data.length; i += 1) {
        setStatusEnum((arr) => [...arr,
          {
            id: data[i], value: userStatusMapper(data[i]),
          }]);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getStatusUsers();
  }, []);

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && (
        <>
          <Box mb={5} sx={{ height: '500', backgroundColor: '#f5f3f4' }}>
            <Grid container sx={{ mx: '10px' }} spacing={5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterSellerFirstName}
                  value={filterValues.firstName}
                  onChange={handleInputChange}
                  label={i18n.filterSellerFirstNameLabel}
                  type="search"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterSellerLastName}
                  value={filterValues.lastName}
                  onChange={handleInputChange}
                  label={i18n.filterSellerLastNameLabel}
                  type="search"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterSellerIdentificationNumber}
                  value={filterValues.identificationNumber}
                  onChange={handleInputChange}
                  label={i18n.filterSellerIdentificationNumberLabel}
                  type="search"
                />
              </Grid>

              <Grid item xs={4}>
                <SelectMenuItems
                  title={i18n.filterSellerStatusLabel}
                  data={statusEnum}
                  value={userStatusMapper(filterValues.status)}
                  onChange={handleInputChangeStatus}
                  name={i18n.filterSellerStatus}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} />

              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterSellerCreatedDateFromLabel}
                    value={filterValues.createdDateFrom}
                    name={i18n.filterSellerCreatedDateFrom}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterSellerCreatedDateFrom)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterSellerCreatedDateUpToLabel}
                    value={filterValues.createdDateTo}
                    name={i18n.filterSellerCreatedDateUpTo}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterSellerCreatedDateUpTo)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button variant="contained" onClick={filterSellerHandler}>
                  {i18n.filterSellerSearch}
                </Button>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

filterSellers.propTypes = {
  showResults: PropTypes.func.isRequired,
};

export default filterSellers;
