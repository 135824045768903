const columnsListSellers = [
  {
    name: 'firstname',
    label: 'Nombre',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'lastname',
    label: 'Apellido',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'identificationNumber',
    label: 'DNI',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'phone',
    label: 'Celular',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Estado',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'createdDate',
    label: 'Fecha Alta',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'id',
    label: 'Detalle',
    options: {},
    filter: false,
    sort: false,
  },
];

export default columnsListSellers;
