import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { Box, Grid } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getFilteredCommerce, getStatus, getSellers } from '../../api/services';
import { statusMapper } from '../../utils/statusMapper';
import i18n from './i18n';
import Loading from './Loading';
import SelectMenuItems from './SelectMenuItems';

const filterCommerce = ({ showResults }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [commerceStates, setCommerceStates] = useState([{
    id: i18n.filterCommerceViewAll,
    value: i18n.filterCommerceViewAll,
  }]);
  const [commerceSellers, setCommerceSellers] = useState([{
    id: i18n.filterCommerceViewAll,
    value: i18n.filterCommerceViewAll,
  }]);

  const [filterValues, setFilterValues] = useState({
    pageNumber: 1,
    pageSize: 100,
    name: '',
    fantasyName: '',
    taxId: '',
    user: i18n.filterCommerceViewAll,
    status: i18n.filterCommerceViewAll,
    dateFrom: null,
    dateTo: null,
  });

  if (filterValues.dateFrom === '') {
    filterValues.dateFrom = null;
  }
  if (filterValues.dateTo === '') {
    filterValues.dateTo = null;
  }
  if (filterValues.status === '') {
    filterValues.status = i18n.filterCommerceViewAll;
  }
  if (filterValues.user === '') {
    filterValues.user = i18n.filterCommerceViewAll;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleInputChangeStatus = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: statusMapper(value) });
  };

  const handleDateChange = (e, name) => {
    const date = (e.toISOString());
    setFilterValues({ ...filterValues, [name]: date });
  };

  const filterCommerceHandler = async () => {
    setIsLoading(true);
    try {
      if (filterValues.dateFrom === null) {
        filterValues.dateFrom = '';
      }
      if (filterValues.dateTo === null) {
        filterValues.dateTo = '';
      }
      if (filterValues.status === i18n.filterCommerceViewAll) {
        filterValues.status = '';
      }
      if (filterValues.user === i18n.filterCommerceViewAll) {
        filterValues.user = '';
      }
      filterValues.status = statusMapper(filterValues.status);

      const { data } = await getFilteredCommerce(filterValues);

      filterValues.status = statusMapper(filterValues.status);
      showResults(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  const getCommerceStatus = async () => {
    setIsLoading(true);
    try {
      const { data } = await getStatus();

      for (let i = 0; i < data.length; i += 1) {
        setCommerceStates((arr) => [...arr,
          {
            id: data[i], value: statusMapper(data[i]),
          }]);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  const getCommerceSellers = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSellers();

      for (let i = 0; i < data.length; i += 1) {
        setCommerceSellers((arr) => [...arr,
          {
            id: data[i].id, value: data[i].id,
          }]);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCommerceStatus();
    getCommerceSellers();
  }, []);

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && (
        <>
          <Box mb={5} sx={{ height: '500', backgroundColor: '#f5f3f4' }}>
            <Grid container sx={{ mx: '10px' }} spacing={5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterName}
                  value={filterValues.razonSocial}
                  onChange={handleInputChange}
                  label={i18n.filterCommerceRazonSocial}
                  type="search"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterFantasyName}
                  value={filterValues.fantasy}
                  onChange={handleInputChange}
                  label={i18n.filterCommerceFantasyName}
                  type="search"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterTaxId}
                  value={filterValues.cuit}
                  onChange={handleInputChange}
                  label={i18n.filterCommerceCuit}
                  type="search"
                />
              </Grid>

              <Grid item xs={4}>
                <SelectMenuItems
                  title={i18n.filterCommerceComercial}
                  data={commerceSellers}
                  value={filterValues.user}
                  onChange={handleInputChange}
                  name={i18n.filterUser}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectMenuItems
                  title={i18n.filterCommerceState}
                  data={commerceStates}
                  value={statusMapper(filterValues.status)}
                  onChange={handleInputChangeStatus}
                  name={i18n.filterStatus}
                />
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterCommerceDateFrom}
                    value={filterValues.dateFrom}
                    name={i18n.filterDateFrom}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterDateFrom)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterCommerceDateUpTo}
                    value={filterValues.dateTo}
                    name={i18n.filterDateTo}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterDateTo)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Button variant="contained" onClick={filterCommerceHandler}>
                  {i18n.filterCommerceSearch}
                </Button>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

filterCommerce.propTypes = {
  showResults: PropTypes.func.isRequired,
};

export default filterCommerce;
